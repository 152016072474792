import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '30-60 minutes',
  session: '1 session',
  results: 'Results 3 - 6 months',
}

const questions: Array<Question> = [
  {
    question: 'How is Btx different from fillers?',
    answer:
      'Btx is different in use than fillers. Dermal fillers work to increase ' +
      'volume, replacing lost collagen and fat, and lifting wrinkles and folds ' +
      'up and out. Botulinum Toxin A, such as Btx, works to relax muscles that ' +
      'cause wrinkles and lines, releasing lines, and preventing further deepening ' +
      'of wrinkles.',
  },
  {
    question: 'How does Btx work?',
    answer:
      'Botulinum Toxin A, branded as Btx, provides a buffer to ' +
      'neurotransmitters within the injected muscles, lessening the ' +
      'signal to contract. This allows the muscle to lengthen, ' +
      'releasing wrinkles and folds. When Btx is injected ' +
      'properly, it only lessens, not eliminates these ' +
      'neurotransmitter signals.',
  },
  {
    question: 'How should I prepare for Btx?',
    answer: 'Start with a cosmetic consultation at Ada Aesthetics.',
  },
]

const DermalFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'BTX: Anti-Wrinkle Injections - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax
        titleName="Btx: Anti-Wrinkle Injections"
        customClass="page_title"
      />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                <picture>
                  <source srcSet="images/blog/08.webp" type="image/webp" />
                  <source srcSet="images/blog/08.jpg" type="image/jpeg" />
                  <img
                    src="images/blog/08.jpg"
                    className="alignright"
                    alt="dermal_fillers"
                  />
                </picture>
              </p>
              <p className="excerpt">
                BTX is a non-surgical treatment used to treat wrinkles on the
                face and neck.
              </p>
              <p>
                Do you have frown lines between your brows or crow’s feet around
                your eyes? Maybe you have ‘smokers’ lines around the lips even
                though you don’t smoke. These ageing lines are caused by muscle
                contractions whenever you frown, squint, or even pout. Btx
                relaxes the muscles and smooths away these expression lines
                which make us look so tired or angry.
                <br />
                Some people are simply more expressive than others. At Ada
                Aesthetics, careful use of Btx will stop you ageing
                prematurely. Btx really is the ultimate ‘prejuvenation’
                treatment.
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default DermalFillers

export const pageQuery = graphql`
  query BotoxFillers {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
